import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwitchTransition } from 'react-transition-group'
import { useLazyQuery, useMutation } from '@apollo/client'
import { mdiCheck, mdiChevronRight, mdiStop } from '@mdi/js'
import Icon from '@mdi/react'
import styled from '@emotion/styled'
import { CircularProgress, Collapse, Divider, Fade, IconButton } from '@mui/material'
import { MColor, MFlex, MFlexBlock, MFlexItem, MText, px } from '@mprise/react-ui'
import { Section } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { formatQuantity, formatUnitOfMeasure } from '../../shared/formats'
import { ConfirmFinishDialog } from './ConfirmFinishDialog'
import { TagStatus } from '../../components/TagStatus'
import { MaterialIcon } from '../../components/Icon'
import { getRefetchQueriesSearchItems } from './FilterDialog'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { fail } from '../../shared/typescript'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../gql/query/workItems/workTaskByIdWithTaskResults'
import { FINISH_TASK, START_TASK, STOP_TASK } from '../../gql/mutation/statusChange/statusChange'
import { Maybe, WorkItemTemplateTaskOption, WorkItemType, WorkStatus } from '../../shared/enums'
import { OUTBOUND_BY_WORKITEM_ID } from '../../gql/query/outboundByWorkItemId'
import { LineClamper } from '../../components/LineClamper'
import { redirectTask } from './redirect-task'
import { START_TIME_REG, STOP_TIME_REG } from '../../gql/mutation/timeReg'
import { ValidationIssues } from '../../mprise-light/ValidationIssues'
import { i18n } from '../../i18n/instance'

export const TaskItem = ({
  taskId,
  workItemId,
  type,
  open,
  loading,
  onToggle,
}: {
  taskId: string
  workItemId: string
  type: WorkItemType
  open: boolean
  loading: boolean
  onToggle: (args: { taskId: string }) => void
}) => {
  const h = useHistory()
  const appSettings = useAppSettingsContext()
  const companyId = appSettings.company?.id ?? h.push('/')
  const currentResource = appSettings.resource ?? h.push('/')

  const [getTaskWithResults, taskQuery] = useLazyQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: type },
    },
  })

  useEffect(() => {
    getTaskWithResults()
  }, [])

  const task = taskQuery.data?.nworkTask
  const canStart =
    task?.status === WorkStatus.Todo || task?.status === WorkStatus.OnHold || task?.status === WorkStatus.NotAssigned
  const canStop = task?.status === WorkStatus.InProgress
  const isDone = task?.status === WorkStatus.Done || task?.status === WorkStatus.Closed
  const isFinished = !loading && task?.status !== WorkStatus.Closed
  const isTodo = !loading && task?.status !== WorkStatus.Todo

  const [showFinishConfirmDialog, setShowFinishConfirmDialog] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const [startTaskMutation] = useMutation(START_TASK)
  const [stopTaskMutation] = useMutation(STOP_TASK)
  const [finishTask, finishTaskState] = useMutation(FINISH_TASK)

  const [startTimeReg] = useMutation(START_TIME_REG)
  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const handleClickStart = async () => {
    if (!task) {
      return
    }
    if (task.status !== WorkStatus.Done) {
      if (!currentResource?.id) {
        fail('expects resource id')
      }

      await startTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +task.id,
          currentResourceId: +currentResource?.id,
        },
      }).then(response => {
        const task = response.data.startTask
        startTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
            resourceId: currentResource.cosmosKey,
          },
        })
      })
    }
    redirectTask(task.workItem, task, h)
  }

  const handleClickStop = async () => {
    await stopTaskMutation({
      variables: {
        workItemId: +workItemId,
        taskId: +taskId,
        currentResourceId: +currentResource?.id!,
      },
    }).then(response => {
      const task = response.data.stopTask
      stopTimeReg({
        variables: {
          workItemId: task.workItem.cosmosKey,
          taskId: task.cosmosKey,
        },
      })
    })
  }

  const handleClickDone = () => {
    const validationError = validateLotRequirements(task)
    if (validationError) {
      setError(validationError)
      return
    }

    if (reportedQuantity !== plannedQuantity) {
      setShowFinishConfirmDialog(true)
    } else {
      handleConfirmFinish()
    }
  }

  const handleConfirmFinish = () => {
    if (!currentResource?.id) {
      fail('expects resource id')
    }

    finishTask({
      variables: { workItemId: +workItemId, taskId: +taskId, currentResourceId: +currentResource?.id },
      refetchQueries: getRefetchQueriesSearchItems(companyId!, currentResource?.id),
    }).then(response => {
      const task = response.data.finishTask
      stopTimeReg({
        variables: {
          workItemId: task.workItem.cosmosKey,
          taskId: task.cosmosKey,
        },
      })
    })

    h.goBack()
  }

  const [reportedQuantity, setReportedQuantity] = useState<Maybe<number>>(null)
  const plannedQuantity = task?.workItem?.plannedQuantity ?? 0

  if (!task) {
    return null
  }

  return (
    <>
      {error && <ValidationIssues.Container>{error}</ValidationIssues.Container>}
      <Section>
        <div className='gh-taskItem-card'>
          <div className='gh-taskItem-card-header' onClick={() => onToggle({ taskId: task.id })}>
            <MFlexItem shrink={0}>
              <IconButton style={{ paddingLeft: '0' }}>
                <Icon path={mdiChevronRight} size={1} rotate={open ? 90 : 0} style={{ transition: `transform 0.3s` }} />
              </IconButton>
            </MFlexItem>
            <div style={{ flexGrow: 1 }}>
              {task?.order ?? `?`}. {task?.name ?? ``}
            </div>
          </div>
          <Collapse in={open}>
            <TaskDetails task={task} setReportedQuantity={setReportedQuantity} />
            <MFlexBlock justifyContent='space-around' padding={[2, 0, 0]}>
              <WowButton
                type='button'
                isBusy={loading}
                bgColor={canStart ? MColor.primary : canStop ? MColor.medium : MColor.status_todo}
                onClick={() => (canStart ? handleClickStart() : canStop ? handleClickStop() : null)}
              >
                <MText block textVariant='header' textColor={MColor.white}>
                  <SwitchTransition>
                    <Fade key={canStart ? 1 : canStop ? 2 : loading ? 3 : 4}>
                      <MFlexBlock justifyContent='center' textColor={MColor.white}>
                        {loading ? (
                          <CircularProgress color='inherit' />
                        ) : (
                          <>
                            {canStart && <MaterialIcon value='play_arrow' style={{ fontSize: '3rem' }} />}
                            {canStop && <MaterialIcon value='pause' style={{ fontSize: '3rem' }} />}
                            {isDone && <Icon color={MColor.white} path={mdiCheck} size={2} />}
                          </>
                        )}
                      </MFlexBlock>
                    </Fade>
                  </SwitchTransition>
                </MText>
              </WowButton>

              <WowButton
                type='button'
                isBusy={loading}
                bgColor={(isFinished && !isTodo) || isDone ? MColor.dim : MColor.high}
                onClick={e => {
                  isTodo && !isDone ? handleClickDone() : e.preventDefault()
                }}
              >
                <MText block textVariant='header' textColor={MColor.white}>
                  <SwitchTransition>
                    <Fade key={canStart ? 1 : canStop ? 2 : loading ? 3 : 4}>
                      <MFlexBlock justifyContent='center'>
                        {(canStart || canStop) && <Icon color={MColor.white} path={mdiStop} size={2} />}
                        {isDone && <Icon color={MColor.white} path={mdiCheck} size={2} />}
                      </MFlexBlock>
                    </Fade>
                  </SwitchTransition>
                </MText>
              </WowButton>
            </MFlexBlock>

            <ItemConsumptionSection itemConsumptionLines={task.workItem.itemConsumption} />
          </Collapse>
          <ConfirmFinishDialog
            open={showFinishConfirmDialog}
            reportedQuantity={reportedQuantity}
            plannedQuantity={plannedQuantity}
            task={task}
            onConfirmFinish={handleConfirmFinish}
            onCancelFinish={() => setShowFinishConfirmDialog(false)}
            finishTaskState={finishTaskState}
          />
        </div>
      </Section>
    </>
  )
}

interface WowButtonProps {
  isBusy: boolean
  bgColor: string
}
const WowButton = styled.button<WowButtonProps>`
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  margin: ${px(14)};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${px(36)};
  width: ${px(72)};
  height: ${px(72)};
  cursor: pointer;
  user-select: none;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;

  box-shadow: ${p =>
    p.isBusy
      ? `0 0 0 ${px(2)} ${p.bgColor}, 0 0 0 ${px(14)} ${MColor.white}, 0 0 0 ${px(16)} ${MColor.disabled}`
      : `0 0 0 ${px(6)} ${p.bgColor}, 0 0 0 ${px(10)} ${MColor.white}, 0 0 0 ${px(12)} ${MColor.disabled}`};

  :active {
    box-shadow:
      0 0 0 ${px(10)} ${p => p.bgColor},
      0 0 0 ${px(12)} ${MColor.white},
      0 0 0 ${px(14)} ${MColor.disabled};
  }
`

export const TaskDetails = ({
  task,
  reportedQuantityOverride,
  setReportedQuantity,
}: {
  task: any
  reportedQuantityOverride?: Maybe<number>
  setReportedQuantity?: Dispatch<SetStateAction<number | null>>
}) => {
  const { t } = useTranslation()

  const workItemId = task?.workItem?.id

  const hasJobInventoryPutAway = task.workItem.jobInventoryPutAway?.length
  const isItemConsumption = task.workItem.itemConsumption?.length
  const isItemOutput = task.workItem.itemOutput?.length

  const plannedItemOutput = task.workItem.itemOutput?.length ? task.workItem.itemOutput[0].planned : null
  const plannedJobInventoryPutAway = task.workItem.jobInventoryPutAway?.length
    ? task.workItem.jobInventoryPutAway[0].planned
    : null
  const plannedItemConsumption = task.workItem.itemConsumption?.length ? task.workItem.itemConsumption[0].planned : null

  // Careful: position in planned is sometimes planned/current
  const plannedPosition =
    plannedJobInventoryPutAway?.position?.code ||
    plannedItemOutput?.outputPosition?.code ||
    (plannedItemConsumption ? task.workItem?.plannedPositions[0]?.code : null)

  const currentPosition = plannedItemOutput?.position?.code
  const plannedQuantity = task?.workItem?.plannedQuantity ?? 0
  const unitOfMeasure = formatUnitOfMeasure(task?.workItem?.unitOfMeasure)
  const { numberFormat } = useAppSettingsContext()

  const [getReportedQuantity, { data, loading }] = useLazyQuery(OUTBOUND_BY_WORKITEM_ID, {
    fetchPolicy: `network-only`,
  })

  useEffect(() => {
    if (
      task.workItem.jobInventoryPutAway?.length &&
      !task.workItem.itemConsumption?.length &&
      workItemId &&
      !task.taskOptions.includes(
        WorkItemTemplateTaskOption.CropMaintenanceGh || WorkItemTemplateTaskOption.BypassJobInventoryGh,
      )
    ) {
      getReportedQuantity({
        variables: {
          workItemId: +workItemId,
        },
      })
    }
  }, [])

  let reportedQuantity = 0
  let reportedQuantityFromQuery = false

  if (reportedQuantityOverride) {
    reportedQuantity = reportedQuantityOverride
  } else {
    if (hasJobInventoryPutAway && !isItemConsumption) {
      if ((task.taskOptions ?? []).includes(WorkItemTemplateTaskOption.CropMaintenanceGh)) {
        reportedQuantity = task.workItem.actualQuantity ?? 0
      } else if ((task.taskOptions ?? []).includes(WorkItemTemplateTaskOption.BypassJobInventoryGh)) {
        reportedQuantity =
          task.workItem.jobInventoryPutAway[0].reported.reduce((a: any, b: any) => a + (b.quantity ?? 0), 0) ?? 0
      } else {
        reportedQuantity = (data?.outboundJobInventoryByWorkItemId ?? []).reduce(
          (a: any, b: any) => a + (b?.quantity ?? 0),
          0,
        )
        reportedQuantityFromQuery = true
      }
    } else if (isItemOutput) {
      reportedQuantity = task.workItem.itemOutput[0].reported.reduce((a: any, b: any) => a + (b.quantity ?? 0), 0) ?? 0
    } else if (isItemConsumption && hasJobInventoryPutAway) {
      reportedQuantity =
        task.workItem.jobInventoryPutAway[0].reported.reduce((a: any, b: any) => a + (b.quantity ?? 0), 0) ?? 0
    }
  }

  useEffect(() => {
    setReportedQuantity?.(reportedQuantity)
  }, [reportedQuantity])

  const plannedLotNumbers = isItemConsumption
    ? task.workItem.itemConsumption
        .flatMap((x: any) => x.planned.lotNumbers)
        .map((lot: any) => lot.lotNumber)
        .join(', ')
    : ''

  return (
    <div className='gh-taskItem-card-content'>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div>{task.workItem?.jobs?.[0]?.code}</div>
        <TagStatus status={task.status} />
      </div>
      <div>{task.workItem?.itemDescription}</div>
      <div>{`${t(`QUANTITY`)}: ${formatQuantity(plannedQuantity, numberFormat)} ${unitOfMeasure}`}</div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {`${t(`ACTUAL`)}: `}
        {reportedQuantityFromQuery && loading ? (
          <CircularProgress size='1rem' color='inherit' />
        ) : (
          `${formatQuantity(reportedQuantity, numberFormat)} ${unitOfMeasure}`
        )}
      </div>
      {currentPosition && <div>{`${t(`CURRENT_POSITION`)}: ${currentPosition}`}</div>}
      {plannedPosition && <div>{`${t(`PLANNED_POSITION`)}: ${plannedPosition}`}</div>}
      {plannedLotNumbers.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>{plannedLotNumbers.length === 1 ? `${t(`LOT`)}` : `${t(`LOTS`)}:`}&nbsp;</div>
          <LineClamper text={plannedLotNumbers} />
        </div>
      )}
    </div>
  )
}

const ItemConsumptionSection = ({ itemConsumptionLines }: { itemConsumptionLines: any }) => {
  const { t } = useTranslation()
  const { numberFormat } = useAppSettingsContext()

  if (!itemConsumptionLines || itemConsumptionLines.length < 1) {
    return null
  }

  return (
    <>
      <div style={{ marginTop: '1.5rem' }}>{t(`WorkResultType.ITEM_CONSUMPTION`)}</div>

      <div className='gh-taskItem-card-content'>
        {itemConsumptionLines.map((consumptionLine: any, index: number) => {
          let totalReportedQuantity = 0
          const reportedQuantities = []
          const reportedLotNrs = []
          for (let i = 0; i < consumptionLine.reported?.length ?? 0; i++) {
            const reportedLine = consumptionLine.reported[i]
            totalReportedQuantity += reportedLine.quantity
            const reportedUnitOfMeasure = formatUnitOfMeasure(reportedLine.quantityUnit)
            reportedQuantities.push(
              <div key={i}>
                {reportedLine.quantity?.toLocaleString('nl-NL')} {reportedUnitOfMeasure}
              </div>,
            )
            reportedLotNrs.push(<div key={i}>{reportedLine.lotNumbers?.map((l: any) => l.lotNumber).join(', ')}</div>)
          }

          const formattedPlannedQuantity = formatQuantity(consumptionLine.planned.quantity, numberFormat)
          const formattedReportedQuantity = formatQuantity(totalReportedQuantity, numberFormat)

          const plannedUnitOfMeasure = formatUnitOfMeasure(consumptionLine.planned.quantityUnit)
          return (
            <div key={index}>
              <Divider style={{ margin: '5px 0' }} />
              <MFlex>
                <MFlexItem shrink={0}></MFlexItem>
                <MFlex vertical style={{ lineHeight: '1.5rem', width: '100%', maxWidth: '400px', paddingRight: '3px' }}>
                  <div>{consumptionLine.planned.itemDescription}</div>
                  <MFlex style={{ lineHeight: '1.5rem', whiteSpace: 'nowrap' }} justifyContent='space-between' gap={8}>
                    <div>
                      {t(`PLANNED`)}: {formattedPlannedQuantity} {plannedUnitOfMeasure}
                    </div>
                    <div>
                      {t(`ACTUAL`)}: {formattedReportedQuantity}
                    </div>
                  </MFlex>
                </MFlex>
              </MFlex>
            </div>
          )
        })}
      </div>
    </>
  )
}

/**
 * Validates Lot requirements when a task is about to be finished.
 * In case no Lots were pre-planned for this ItemConsumption, OR the overrule taskOption is on: at least one Lot must be reported.
 * Otherwise, when the overrule option is off, the exact planned Lots must also be reported.
 * @link Also see CreateInventoryForm.validateLotRequirements, which contains the same check but for auto-closing work items.
 */
const validateLotRequirements = (task: any) => {
  const overruleAllowed = task.taskOptions.includes(WorkItemTemplateTaskOption.AllowOverruleLotReservationGh)
  const itemConsumptionsWithLot = task.workItem.itemConsumption?.filter((i: any) => i.planned.lotNumbersRequired)

  let error = ''

  for (const itemConsumption of itemConsumptionsWithLot ?? []) {
    const plannedLots = itemConsumption.planned.lotNumbers ?? []
    const reportedLots = itemConsumption.reported.flatMap((x: any) => x.lotNumbers) ?? []
    if (!plannedLots?.length || overruleAllowed) {
      if (!reportedLots?.length) {
        error = i18n.t('AT_LEAST_ONE_REPORTED_LOT_REQUIRED', { itemName: itemConsumption.planned.itemDescription })
      }
    } else {
      const plannedLotNumbers: string[] = plannedLots.map((x: any) => x.lotNumber)
      const reportedLotNumbers: string[] = reportedLots.map((x: any) => x.lotNumber)
      const missingLotNumbers = plannedLotNumbers.filter(x => !reportedLotNumbers.includes(x))
      error = i18n.t('PLANNED_LOTS_REQUIRED_TO_REPORT', {
        itemName: itemConsumption.planned.itemDescription,
        lotNumbers: missingLotNumbers.join(', '),
      })
    }
  }

  return error
}
