import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Formik, MFieldConnector, MFlexBlock, Yup } from '@mprise/react-ui'
import { JobMutationsEntryForm } from './Home'
import { Section } from '../../components/Section'
import { nameof } from '../../shared/typescript'
import { FieldJob } from '../../shared/form/FieldJob'
import { FieldJobInventoryDetail } from '../../shared/form/FieldJobInventoryDetail'
import { FieldPosition } from '../../shared/form/FieldPosition'
import { SelectedJobInventoryDetails, SelectedPhaseDetails } from '../../shared/form/selectionDetails'
import { useBeforeUnload } from '../../shared/before-unload'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../gql/query/workItems/workTaskByIdWithTaskResults'
import { TaskDetailsSection } from '../../shared/form/TaskDetailsSection'
import { FieldComboQuantityPerc } from '../../shared/form/FieldComboQuantityPerc'
import { JOB_WITH_PHASES } from '../../gql/query/jobs/jobById'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { useHistory } from '../../shared/use-history'
import { FieldComboSortToPosition } from '../../shared/form/FieldComboSortToPosition'
import { Card } from '../../components/Card'
import { CircularProgress } from '@mui/material'
import { FieldPhase } from '../../shared/form/FieldPhase'

export const JobMutationsForm = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const fc = Formik.useFormikContext<JobMutationsEntryForm>()
  const { company: currentCompany } = useAppSettingsContext()
  const companyId = currentCompany?.id ?? h.push('/')
  const taskId = useParams().taskId ?? ''

  const jobFieldRef = useRef<HTMLInputElement>(null)

  useBeforeUnload(!!fc.values.quantity && !fc.isSubmitting)

  const [getTaskWithResults, { data: taskData, loading: taskLoading }] = useLazyQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS)
  const [getJobWithPhases, { data: jobData }] = useLazyQuery(JOB_WITH_PHASES)

  useEffect(() => {
    if (taskId) {
      getTaskWithResults({
        variables: {
          where: [{ field: 'id', options: { eq: +taskId } }],
          filter: { mandatoryType: 'JOB_WORK_ORDER' },
        },
      })
    }
  }, [taskId, getTaskWithResults])

  const task = taskData?.nworkTask
  const jobWithPhases = jobData?.job
  const selectedJid = fc.values.jobInventoryDetail
  const selectedPhase = fc.values.toPhase

  /** Prefill form if this is a Task being executed. */
  useEffect(() => {
    const job = task?.workItem.jobs?.[0]
    const toPosition = task?.workItem.jobInventoryPutAway?.[0]?.planned.position
    if (task && job && toPosition) {
      fc.setValues(current => ({
        ...current,
        job: job,
        toPosition: toPosition,
        workItemCosmosKey: task.workItem?.cosmosKey,
        taskCosmosKey: task.cosmosKey,
      }))
    }
  }, [task, fc])

  /** (Re-)trigger job query when selectedJobId changes. */
  useEffect(() => {
    if (companyId) {
      const selectedJobId = fc.values.job?.id
      if (selectedJobId && jobWithPhases?.id !== selectedJobId) {
        fc.setValues(current => ({
          ...current,
          sortingEntries: [],
          jobInventoryDetail: null,
          toPhase: null,
        }))

        getJobWithPhases({
          variables: {
            filter: {
              companyId: +companyId,
              id: +selectedJobId,
            },
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: 'fc' change shouldn't trigger this effect, causes an infinite loop
  }, [fc.values.job, jobWithPhases, getJobWithPhases, companyId])

  /** Update form sortingEntries whenever the selected JobInventory changes. */
  useEffect(() => {
    if (selectedJid?.productionPhase) {
      const sortingCodesForCurrentPhase = getSortingCodesForPhase(jobWithPhases, selectedJid.productionPhase)

      if (sortingCodesForCurrentPhase.length) {
        const sortingEntries = sortingCodesForCurrentPhase.map((sortingCode: string) => ({
          sortingCode: sortingCode,
          quantity: sortingCode === selectedJid.sortingCode ? selectedJid.remainingQuantity : 0,
          toPosition: selectedJid.position,
        }))
        fc.setFieldValue('sortingEntries', sortingEntries)
      }
    }
    if (selectedJid?.position) {
      fc.setFieldValue('toPosition', selectedJid.position)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: Only needs to trigger when selectJid changes, not more often.
  }, [selectedJid])

  /** Update form sortingEntries whenever the selected Phase changes. */
  useEffect(() => {
    if (selectedPhase?.id && selectedJid) {
      const sortingCodesForSelectedPhase = getSortingCodesForPhase(jobWithPhases, selectedPhase.productionPhase)

      const sortingEntries = sortingCodesForSelectedPhase.map((sortingCode: string) => ({
        sortingCode: sortingCode,
        quantity: sortingCode === selectedJid.sortingCode ? selectedJid.remainingQuantity : 0,
        toPosition: selectedJid.position,
      }))
      fc.setFieldValue('sortingEntries', sortingEntries)
      fc.setFieldValue('toPosition', selectedJid.position)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: Only needs to trigger when selectedPhase changes, not more often.
  }, [selectedPhase])

  /** Set focus to the Job field when the form opens. */
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [jobFieldRef])

  const unitOfMeasure = selectedJid?.unitOfMeasure ?? ''
  const sortingEntries = fc.values.sortingEntries
  const jobHasSortings = !!sortingEntries.length

  if (taskLoading) {
    return (
      <MFlexBlock justifyContent='center' padding={10}>
        <CircularProgress />
      </MFlexBlock>
    )
  }

  return (
    <>
      {task && <TaskDetailsSection task={task} />}

      {/* Primary inputs: will decide what type of action this page will do, and what other fields will show in the section below */}
      <Section>
        <Card>
          <Formik.Field component={MFieldConnector} name={nameof<JobMutationsEntryForm>('job')} color={'#6a707e'}>
            <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<JobMutationsEntryForm>('fromPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={'FROM_POSITION'} neverDisable />
          </Formik.Field>
          <div style={{ display: 'none' }}>
            <Formik.Field component={MFieldConnector} name={nameof<JobMutationsEntryForm>('jobInventoryDetail')}>
              <FieldJobInventoryDetail title={t('ASSIGN_JOB_INVENTORY_DETAIL')} />
            </Formik.Field>
          </div>
          <Formik.Field component={MFieldConnector} name={nameof<JobMutationsEntryForm>('toPhase')}>
            <FieldPhase title={t('TO_PHASE')} phase={selectedPhase} jobInventoryDetail={fc.values.jobInventoryDetail} />
          </Formik.Field>
        </Card>
      </Section>

      {/* Secondary inputs: initially hidden, content decided based on primary input values */}
      <Section>
        <Card>
          {jobHasSortings ? (
            <FieldComboSortToPosition
              selectedJid={selectedJid}
              selectedPhase={selectedPhase}
              sortingEntries={sortingEntries}
              unitOfMeasure={unitOfMeasure}
              maxQuantity={fc.values.originalQuantity}
              setSortingEntries={sortingEntries => fc.setFieldValue('sortingEntries', sortingEntries)}
            />
          ) : (
            <>
              <Formik.Field component={MFieldConnector} name={nameof<JobMutationsEntryForm>('toPosition')}>
                <FieldPosition title={t('ASSIGN_POSITION')} label={'TO_POSITION'} neverDisable />
              </Formik.Field>
              <FieldComboQuantityPerc
                quantity={fc.values.quantity}
                maxQuantity={fc.values.originalQuantity}
                unitOfMeasure={unitOfMeasure}
                setQuantity={quantity => fc.setFieldValue('quantity', quantity)}
              />
            </>
          )}
        </Card>
      </Section>

      <Section>
        <SelectedJobInventoryDetails jobInventoryDetail={selectedJid} showPosition={false} />
      </Section>

      {selectedPhase && (
        <Section>
          <SelectedPhaseDetails phase={selectedPhase} />
        </Section>
      )}
    </>
  )
}

JobMutationsForm.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(
    (): Yup.SchemaOf<JobMutationsEntryForm> =>
      Yup.object().shape({
        job: Yup.object()
          .shape({
            id: Yup.string().required('Job is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required('Job is a required field')
          .nullable()
          .label(t('JOB')),
        jobInventoryDetail: Yup.mixed().required().label(t('JOB_INVENTORY_DETAIL')),
        fromPosition: Yup.object()
          .shape({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .nullable(),
        toPosition: Yup.object()
          .shape({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .test('position required', 'Position is a required field', (input, context) => {
            if (!context.parent.sortingEntries.length) {
              return !!input?.id
            }
            return true
          })
          .test('position', t('NOTIFICATION_POSITION_INVALID'), (input, context: { parent: JobMutationsEntryForm }) => {
            if (
              !context.parent.sortingEntries.length &&
              !context.parent.isWorkItemTransfer &&
              context.parent.toPhase?.name === context.parent.jobInventoryDetail?.productionPhase &&
              context.parent.jobInventoryDetail?.position
            ) {
              return input?.code !== context.parent.jobInventoryDetail.position?.code
            }
            return true
          })
          .nullable()
          .label(t('POSITION')),
        toPhase: Yup.mixed().nullable(),
        originalQuantity: Yup.number().nullable().defined().label('originalQuantity'),
        quantity: Yup.mixed()
          .test('quantity 1', 'Quantity is required', (input, context) => {
            if (!context.parent.sortingEntries.length) {
              return input > 0
            }
            return true
          })
          .nullable(),
        sortingEntries: Yup.array()
          .of(
            Yup.object()
              .shape({
                toPosition: Yup.object()
                  .shape({
                    id: Yup.string().nullable(),
                    name: Yup.string().nullable(),
                    code: Yup.string().nullable(),
                  })
                  .nullable(),
                quantity: Yup.number().nullable(true),
                sortingCode: Yup.string().required('Sorting is required'),
              })
              .test({
                name: 'position in sorting',
                exclusive: false,
                message: input => `${t('SELECT_POSITION_FOR_SORTING')} ${input.value.sortingCode}`,
                test: input => !(input.quantity && input.quantity > 0 && !input.toPosition?.id),
              })
              .optional(),
          )
          .test('quantity', t('ADD_QUANTITY_FOR_A_FIELD'), input => {
            if (input?.length) {
              const quantity = input.reduce((a: any, b: any) => a + b.quantity, 0)
              return quantity > 0
            }
            return true
          }),
        isWorkItemTransfer: Yup.boolean().required(),
        workItemId: Yup.mixed().optional().nullable(),
        workItemCosmosKey: Yup.mixed().optional().nullable(),
        taskCosmosKey: Yup.mixed().optional().nullable(),
      }),
  )

  return schema
}

function getSortingCodesForPhase(jobWithPhases: any, forPhase: string): string[] {
  return (jobWithPhases?.jobProductionPhases ?? [])
    .filter((x: any) => x.productionPhase === forPhase && x.sortingCode !== '')
    .map((x: any) => x.sortingCode)
}
