import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputProps } from '@mui/material'
import { useMField } from '@mprise/react-ui/dist/controls/mfield/context'
import { useAppSettingsContext } from '../context/AppSettingsContext'
import { FieldInteger, MinusButton, PlusButton } from './FieldInteger'

/** Amount of digits supported after the decimal separator. */
const DECIMAL_FRACTION_DIGITS = 1

// Copied and adjusted from react-ui.
export const FieldDecimal = (props: InputProps & { step?: number; max?: number }) => {
  const { t } = useTranslation()
  const f = useMField()

  const { numberFormat } = useAppSettingsContext()

  const max = props.max
  const step = props.step ?? 1

  const [focused, setFocused] = useState(false)
  const [plusDisabled, setPlusDisabled] = useState(max === 0)

  const handleMinus = (e: any) => {
    let newValue
    if (numberFormat.id === 'DECIMAL_COMMA') {
      const currentValue = f.value ? parseFloat(f.value.toString().replace(',', '.')) : 0
      newValue = Math.max(0, currentValue - step)
      f.onChange?.(newValue.toFixed(DECIMAL_FRACTION_DIGITS).replace('.', ','), e)
    } else {
      const currentValue = parseFloat(f.value ?? 0)
      newValue = Math.max(0, currentValue - step)
      f.onChange?.(newValue.toFixed(DECIMAL_FRACTION_DIGITS), e)
    }
    if (max && newValue < max && plusDisabled) {
      setPlusDisabled(false)
    }
  }

  const handlePlus = (e: any) => {
    if (numberFormat.id === 'DECIMAL_COMMA') {
      const currentValue = f.value ? parseFloat(f.value.toString().replace(',', '.')) : 0
      const newValue = currentValue + step
      if (max == null || newValue <= max) {
        f.onChange?.(newValue.toFixed(DECIMAL_FRACTION_DIGITS).replace('.', ','), e)
      } else if (!plusDisabled) {
        setPlusDisabled(true)
      }
    } else {
      const currentValue = f.value ? parseFloat(f.value) : 0
      const newValue = currentValue + step
      if (max == null || newValue <= max) {
        f.onChange?.(newValue.toFixed(DECIMAL_FRACTION_DIGITS), e)
      } else if (!plusDisabled) {
        setPlusDisabled(true)
      }
    }
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    f.onChange?.(e.target.value ?? '', e)
  }

  const handleFocus = () => setFocused(true)
  const handleBlur = () => setFocused(false)

  return (
    <FieldInteger.Container focused={focused} style={{ width: '161px' }}>
      <MinusButton handleMinus={handleMinus} />
      {numberFormat.id === 'DECIMAL_COMMA' ? (
        <Input
          name={f?.name || ''}
          id={f?.id || ''}
          value={Number.isNaN(Number(f.value)) ? f.value || '' : f.value}
          type='text'
          inputMode='numeric'
          title={t('INVALID_FORMAT_QUANTITY', { NUMBER: '1,5' })}
          onChange={handleChange}
          disableUnderline
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputProps={{
            pattern: '^(?!.*\\.\\d{1,2}$)[0-9.]+(?:,[0-9]+)?$',
            style: { textAlign: 'center' },
          }}
          {...props}
        />
      ) : (
        <Input
          name={f?.name || ''}
          id={f?.id || ''}
          value={Number.isNaN(Number(f.value)) ? f.value || '' : f.value}
          type='text'
          inputMode='numeric'
          title={t('INVALID_FORMAT_QUANTITY', { NUMBER: '1.5' })}
          onChange={handleChange}
          disableUnderline
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputProps={{
            pattern: '^(?!.*,\\d{1,2}$)[0-9,]+(?:\\.[0-9]+)?$',
            style: { textAlign: 'center' },
          }}
          {...props}
        />
      )}
      <PlusButton handlePlus={handlePlus} disabled={plusDisabled} />
    </FieldInteger.Container>
  )
}
