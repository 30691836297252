import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Button, ButtonGroup } from '@mui/material'
import { MBlock, MColor, MFlexBlock, MText } from '@mprise/react-ui'
import { defined } from '../typescript'
import { PhaseCard } from '../../routes/phase/PhaseCard'
import { QueryErrorMessage } from '../apollo'
import { useLocalState } from '../local-state'
import { MaterialIcon } from '../../components/Icon'
import { DialogWithBackButton } from '../../components/Dialog'
import { JOB_WITH_PHASES } from '../../gql/query/jobs/jobById'
import { JobInventoryDetail, JobProductionPhase } from '../interfaces'
import { Maybe } from '../enums'

export const SelectPhaseDialog = ({
  title,
  open,
  onClose,
  onSave,
  jobInventoryDetail,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
  jobInventoryDetail: Maybe<JobInventoryDetail> | undefined
}) => {
  const { t } = useTranslation()

  const jobId = jobInventoryDetail?.job?.id ?? ''

  const [showPreviousPhases, setShowPreviousPhases] = useLocalState(false, [open])
  const [showFuturePhases, setShowFuturePhases] = useLocalState(true, [open])

  const changeShowPreviousPhases = () => {
    setShowPreviousPhases(!showPreviousPhases)
  }
  const changeShowFuturePhases = () => {
    setShowFuturePhases(!showFuturePhases)
  }

  const [phaseQuery, { error, loading, data: phaseQueryData, refetch }] = useLazyQuery(JOB_WITH_PHASES)

  useEffect(() => {
    if (open && jobId) {
      phaseQuery({
        variables: {
          filter: {
            id: +jobId,
          },
        },
      })
    }
  }, [open, jobId])

  const handleSelect = (phase: JobProductionPhase) => {
    onSave(phase)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const phases: JobProductionPhase[] = phaseQueryData?.job?.jobProductionPhases?.filter(defined) ?? []
  const originJobProductionPhase = phases.find(phase => phase.productionPhase === jobInventoryDetail?.productionPhase)
  const originParentLineNo = originJobProductionPhase?.parentLineNo ?? 0

  // Filter phases based on parentLineNo. Future phases have greater parentLineNo than origin.
  const filteredPhases = phases
    .sort((a, b) => a.parentLineNo - b.parentLineNo)
    .filter((phase, i, a) => {
      if (phase.parentLineNo === a[i - 1]?.parentLineNo || phase.noOfSortSubLines !== 0) {
        // Production phases can occur multiple times (e.g. in case of sortings). Only show one per parentLineNo.
        return false
      } else if (showFuturePhases && showPreviousPhases) {
        return phase.parentLineNo !== originParentLineNo
      } else if (showFuturePhases) {
        return phase.parentLineNo > originParentLineNo
      } else if (showPreviousPhases) {
        return phase.parentLineNo < originParentLineNo
      } else {
        return false
      }
    })

  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={{ error, loading, refetch }} />
      <PhaseFilter
        showPreviousPhases={showPreviousPhases}
        showFuturePhases={showFuturePhases}
        handleChangePrevious={changeShowPreviousPhases}
        handleChangeFuture={changeShowFuturePhases}
      />
      <MFlexBlock vertical style={{ overflow: 'auto', position: 'relative' }}>
        {filteredPhases.length > 0 ? (
          filteredPhases.map(phase => <PhaseCard key={phase.id} phase={phase} handleSelect={handleSelect} />)
        ) : (
          <MBlock padding={5}>
            <MText block>{t('NOTIFICATION_NO_PHASES')}</MText>
          </MBlock>
        )}
      </MFlexBlock>
    </DialogWithBackButton>
  )
}

const PhaseFilter = ({
  showPreviousPhases,
  showFuturePhases,
  handleChangePrevious,
  handleChangeFuture,
}: {
  showPreviousPhases: boolean
  showFuturePhases: boolean
  handleChangePrevious: () => void
  handleChangeFuture: () => void
}) => {
  const { t } = useTranslation()

  return (
    <ButtonGroup
      variant='text'
      sx={{ width: '100%', justifyContent: 'center', borderBottom: '1px lightgrey solid', pb: '5px' }}
      size='large'
      fullWidth
    >
      <Button
        sx={{ color: showPreviousPhases ? MColor.primary : MColor.dim }}
        style={{ borderRightColor: 'lightgrey' }}
        onClick={handleChangePrevious}
      >
        {showPreviousPhases && (
          <MaterialIcon style={{ color: MColor.primary, fontSize: '1.3rem', marginRight: '0.5rem' }} value='check' />
        )}
        {t('PREVIOUS_PHASES')}
      </Button>
      <Button sx={{ color: showFuturePhases ? MColor.primary : MColor.dim }} onClick={handleChangeFuture}>
        {showFuturePhases && (
          <MaterialIcon style={{ color: MColor.primary, fontSize: '1.3rem', marginRight: '0.5rem' }} value='check' />
        )}
        {t('FUTURE_PHASES')}
      </Button>
    </ButtonGroup>
  )
}
