import React, { useEffect, useState } from 'react'
import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import { Divider, IconButton, InputAdornment, InputBase, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import { ScanningSetting, useAppSettingsContext } from '../../context/AppSettingsContext'
import {
  BarcodeInputStatus,
  InstantScanButton,
  MColor,
  MFlexBlock,
  fontFamily,
  px,
  spacing,
  useBarCodeInputHooks,
  useCombinedRefs,
} from '@mprise/react-ui'
import { getInputMode } from '../../mprise-light/FieldBarcode'
import { CssCustomizable } from './customizable'
import styled from '@emotion/styled'

export interface IBarCodeInputMaterial {
  tabIndex?: number
  scanningSetting: ScanningSetting
  search?: boolean
  id?: string
  name?: string
  value: string
  onChange: (v: string) => void
  onSubmit?: (v: string) => void
  autoFocus?: boolean
  disabled?: boolean
  openCameraOnFocus?: boolean
  dialogMode?: boolean
  status: BarcodeInputStatus
  optionalAdornment?: JSX.Element
  onFocus?: () => void
  onBlur?: () => void
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
  showSwitchKeyboardOption?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const BarCodeInputMaterial = React.forwardRef<HTMLInputElement, IBarCodeInputMaterial>(
  (
    {
      autoFocus,
      dialogMode,
      openCameraOnFocus,
      disabled,
      scanningSetting,
      optionalAdornment,
      search,
      id,
      name,
      value,
      status,
      onChange,
      onSubmit,
      tabIndex,
      onFocus,
      onClick,
      onBlur,
      onKeyPress,
      showSwitchKeyboardOption,
    },
    ref,
  ) => {
    const { handleChange, handleScannedCode } = useBarCodeInputHooks(onChange, onSubmit, status)
    const [localScanningSetting, setLocalScanningSetting] = useState<ScanningSetting>(scanningSetting)
    useEffect(() => {
      setLocalScanningSetting(scanningSetting)
    }, [scanningSetting])
    const [shouldOpen, setShouldOpen] = useState<boolean>(false)
    const [closed, setClosed] = useState<boolean>(false)
    const innerRef = React.useRef<HTMLInputElement>(null)
    const combinedRef = useCombinedRefs<HTMLInputElement | HTMLDivElement>(ref, innerRef)

    const hasValue: boolean = value.length > 0

    const showKeyboardOption = localScanningSetting === ScanningSetting.CAMERA && showSwitchKeyboardOption && !disabled

    const { scanSetting } = useAppSettingsContext()
    const showInstantScan = scanSetting === ScanningSetting.CAMERA && !disabled

    const showOptionalAdornment = !!optionalAdornment && !disabled
    const showOptionalDivider = showInstantScan && showOptionalAdornment && !dialogMode

    const inputMode = getInputMode(localScanningSetting)

    const handleClear = () => onChange('')

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = evt => {
      if (evt.key === `Enter`) {
        evt.preventDefault()
        evt.currentTarget.setSelectionRange(0, evt.currentTarget.value.length)
        onSubmit?.(evt.currentTarget.value)
      }

      onKeyPress?.(evt)
    }

    const handleFocus = () => {
      if (showInstantScan && value.length === 0 && !closed && openCameraOnFocus) {
        setShouldOpen(true)
      }
      onFocus?.()
    }

    const handleBlur = () => {
      onBlur?.()
    }

    const onUseKeyboard = () => {
      setLocalScanningSetting(ScanningSetting.KEYBOARD)
    }

    useEffect(() => {
      if (localScanningSetting === ScanningSetting.KEYBOARD) {
        // can be both input or div with input as a child
        if (combinedRef.current) {
          const input = combinedRef.current.querySelector('input')
          if (input) {
            input.focus()
          } else {
            // if there is no input one can safely assume the current ref is the input field
            combinedRef.current.focus()
          }
        }
      }
    }, [localScanningSetting])

    const startAdornment = search ? (
      <InputAdornment position='start' style={{ marginLeft: spacing(1) }}>
        <SearchIcon />
      </InputAdornment>
    ) : null

    const endAdornment = (
      <InputAdornment position='end'>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {hasValue && (
            <React.Fragment>
              <ClearButton onClick={handleClear} disabled={disabled} />
            </React.Fragment>
          )}
          {showKeyboardOption && (
            <>
              <AdornmentDivider disabled={disabled} dialogMode={dialogMode} />
              <IconButton onClick={onUseKeyboard}>
                <KeyboardIcon />
              </IconButton>
            </>
          )}
          {showInstantScan && (
            <>
              <AdornmentDivider disabled={disabled} dialogMode={dialogMode} />

              <InstantScanButton
                shouldOpen={shouldOpen}
                onClose={() => {
                  setClosed(true)
                  if (!dialogMode) {
                    combinedRef.current?.blur()
                  }
                  setShouldOpen(false)
                }}
                onScanned={({ code }) => {
                  if (!dialogMode) {
                    combinedRef.current?.blur()
                  }
                  setShouldOpen(false)
                  setClosed(false)
                  handleScannedCode(code)
                }}
              />
            </>
          )}
          {showOptionalDivider && <AdornmentDivider disabled={disabled} dialogMode={dialogMode} />}
          {showOptionalAdornment && optionalAdornment}
        </div>
      </InputAdornment>
    )

    if (search) {
      return (
        <StyledSearchField
          inputProps={{
            autoCapitalize: 'off',
            autoComplete: 'off',
            autoCorrect: 'off',
            autoFocus: autoFocus,
            inputMode: inputMode,
            tabIndex: tabIndex,
            onKeyPress: handleKeyPress,
          }}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          disabled={disabled}
          fullWidth={true}
          name={name}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onClick={onClick}
          ref={combinedRef}
        />
      )
    } else if (dialogMode) {
      return (
        <MFlexBlock bgColor={MColor.white} variant='rounded' alignItems='center' grow={1} gap={2}>
          <StyledDialogField
            inputProps={{
              autoCapitalize: 'off',
              autoComplete: 'off',
              autoCorrect: 'off',
              autoFocus: autoFocus,
              inputMode: inputMode,
              tabIndex: tabIndex,
              onKeyPress: handleKeyPress,
            }}
            endAdornment={endAdornment}
            startAdornment={startAdornment}
            disabled={disabled}
            fullWidth={true}
            name={name}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            ref={combinedRef}
          />
        </MFlexBlock>
      )
    } else {
      return (
        <TextField
          inputProps={{
            autoCapitalize: 'off',
            autoComplete: 'off',
            autoCorrect: 'off',
            autoFocus: autoFocus,
            inputMode: inputMode,
            tabIndex: tabIndex,
            onKeyPress: handleKeyPress,
          }}
          InputProps={{
            startAdornment,
            endAdornment,
          }}
          disabled={disabled}
          fullWidth={true}
          name={name}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          ref={combinedRef}
          variant='standard'
        />
      )
    }
  },
)

const ClearButton = ({
  className,
  style,
  onClick,
  disabled,
}: CssCustomizable<{ onClick: () => void; disabled?: boolean }>) =>
  !disabled ? (
    <IconButton className={className} style={style} onClick={onClick} disabled={disabled}>
      <Icon size={1} path={mdiClose} color='currentColor' />
    </IconButton>
  ) : null

const AdornmentDivider = ({ disabled, dialogMode }: { disabled?: boolean; dialogMode?: boolean }) =>
  !disabled ? (
    <Divider
      orientation='vertical'
      style={{ margin: dialogMode ? ' 0px 20px 0px 10px' : `auto ${px(1)}`, height: dialogMode ? '28px' : px(20) }}
    />
  ) : null

const StyledSearchField = styled(InputBase)({
  root: {
    background: MColor.paper,
    boxShadow: `0 ${px(1)} ${px(2)} rgba(0, 0, 0, 0.08), 0 ${px(4)} ${px(12)} rgba(0, 0, 0, 0.06)`,
  },
  input: {
    background: MColor.paper,
    padding: `${spacing(2)} ${spacing(1)}`,
    fontSize: px(20),
    fontWeight: 500,
    fontFamily: fontFamily,
  },
})

const StyledDialogField = styled(InputBase)({
  root: {
    background: 'none',
  },
  input: {
    background: 'none',
    padding: `${spacing(3)} ${spacing(2)}`,
    fontSize: px(20),
    fontWeight: 600,
    fontFamily: fontFamily,
  },
})

export default BarCodeInputMaterial
