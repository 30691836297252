import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { useApolloClient, useMutation } from '@apollo/client'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { useLocalState } from '../../shared/local-state'
import { JobMutationsForm } from './JobMutationsForm'
import { FlashAlerts } from '../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../shared/apollo'
import { ValidationIssues } from '../../mprise-light/ValidationIssues'
import { NumberFormatItem, useAppSettingsContext } from '../../context/AppSettingsContext'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { REPORT_TRANSFER_TO_POSITION } from '../../gql/mutation/reportTransferToPosition'
import { STOP_TASK } from '../../gql/mutation/statusChange/statusChange'
import { parseFloatQuantity } from '../../shared/formats'
import { Maybe } from '../../shared/enums'
import { JobInventoryDetail, JobProductionPhase, Position } from '../../shared/interfaces'
import { fail } from '../../shared/typescript'
import { STOP_TIME_REG } from '../../gql/mutation/timeReg'
import { SortingFormEntry } from '../../shared/form/FieldComboSortToPosition'
import { REPORT_SORT_AND_TRANSFER } from '../../gql/mutation/reportSortAndTransferToPosition'

export interface JobMutationsEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<{ id: string; name?: Maybe<string> }> }>
  fromPosition: Maybe<Position>
  toPosition: Maybe<Position>
  toPhase?: Maybe<JobProductionPhase>
  sortingEntries: Array<SortingFormEntry>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  isWorkItemTransfer: boolean
  workItemId: Maybe<string>
  workItemCosmosKey: Maybe<string>
  taskCosmosKey: Maybe<string>
}

export const JobMutationsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = JobMutationsForm.useSchema()
  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache
  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  // If Transfer is used by a redirect of a WorkOrder: Prefill form
  const workItemId = useParams().workItemId ?? null
  const taskId = useParams().taskId
  // If phaseCode is defined: do an automatic phase transition at transfer
  const phaseCodeText = useParams().phaseCode
  const phaseCode = phaseCodeText === 'null' ? null : phaseCodeText

  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)

  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const handleClose = async (autoFinished?: boolean, e?: React.FormEvent<Element>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (workItemId && taskId && !autoFinished) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
      }).then(response => {
        const task = response.data.stopTask
        stopTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
          },
        })
      })
    }
    h.goBack()
  }

  const [reportTransferToPosition, reportTransferToPositionState] = useMutation(REPORT_TRANSFER_TO_POSITION)
  const [reportSortAndTransfer, reportSortAndTransferState] = useMutation(REPORT_SORT_AND_TRANSFER)

  const [initialValues] = useLocalState((): JobMutationsEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      fromPosition: { id: '', name: '', code: '' },
      toPosition: { id: '', name: '', code: '' },
      sortingEntries: [],
      jobInventoryDetail: null,
      originalQuantity: null,
      quantity: null,
      isWorkItemTransfer: workItemId ? true : false,
      workItemId: workItemId ?? null,
      workItemCosmosKey: null,
      taskCosmosKey: null,
    })
  }, [])

  const handleSubmit = async (form: JobMutationsEntryForm, actions: Formik.FormikHelpers<JobMutationsEntryForm>) => {
    actions.resetForm()

    if (!resourceId) {
      fail('expects resource id')
    }
    const originJobInventoryDetailId = form.jobInventoryDetail!.id

    const sortingEntries = form.sortingEntries
    const jobHasSortings = !!sortingEntries.length

    if (jobHasSortings) {
      const response = await reportSortAndTransfer({
        variables: {
          originJobInventoryDetailId: +originJobInventoryDetailId,
          sortAndTransferDestinations: getSortAndTransferDestinations(sortingEntries, numberFormat),
          destinationPhase: phaseCode ?? form.toPhase?.productionPhase,
          resourceId: +resourceId,
        },
      })
      if (response.data) {
        MAudio.scanSuccess()
        alerts.push(t('SUCCESS_MESSAGE'), 'success')
      }
    } else {
      const response = await reportTransferToPosition({
        variables: {
          originJobInventoryDetailId: +originJobInventoryDetailId,
          destinationPositionId: +form.toPosition!.id,
          quantity: parseFloatQuantity(form.quantity!, numberFormat),
          resourceId: +resourceId,
          destinationPhase: phaseCode ?? form.toPhase?.productionPhase,
          workItemId: workItemId ? +workItemId : null,
        },
      })
      if (response.data) {
        MAudio.scanSuccess()
        alerts.push(t('SUCCESS_MESSAGE'), 'success')

        if (response.data.transferPositionJobInventoryDetailGH.autoFinished) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
          if (form.workItemCosmosKey && form.taskCosmosKey) {
            stopTimeReg({
              variables: {
                workItemId: form.workItemCosmosKey,
                taskId: form.taskCosmosKey,
              },
            })
            handleClose(true)
          }
        }
      }
    }
  }

  return (
    <>
      <Helmet title={t('JOB_MUTATIONS')} />

      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik minWidth='xl' title={t('JOB_MUTATIONS')} onClose={e => handleClose(false, e)} open={true}>
          <SavingSwitchPanel mutation={[reportTransferToPositionState, stopTaskState, reportSortAndTransferState]}>
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[reportTransferToPositionState, stopTaskState, reportSortAndTransferState]}
            />
            <JobMutationsForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}

function getSortAndTransferDestinations(sortingEntries: SortingFormEntry[], numberFormat: NumberFormatItem) {
  return sortingEntries
    .filter(x => x.quantity && x.quantity > 0 && x.toPosition?.id)
    .map((x: any) => ({
      sorting: x.sortingCode,
      quantity: parseFloatQuantity(x.quantity, numberFormat),
      positionId: +x.toPosition.id,
    }))
}
