import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { MaterialIcon } from '../../components/Icon'
import { RoleVisible } from '../../auth'
import { EditPhaseStartDateDialog } from './EditPhaseDialog'
import { formatQuantity, formatDateWithWeekNo, formatUnitOfMeasure } from '../../shared/formats'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { JobInventoryDetail, JobProductionPhase } from '../../shared/interfaces'

export const PhasesSection = ({
  phases,
  jobInventoryDetails,
  unfilteredPhases,
}: {
  phases: JobProductionPhase[]
  jobInventoryDetails: JobInventoryDetail[]
  unfilteredPhases: JobProductionPhase[]
}) => {
  // find all jobInventoryDetails that have the same phase and sortingCode. Combine quantity
  // sortingCode in cosmos can be '', which corresponds to null in postgres.
  const combinedPhases = phases.map(phase => {
    const jids = jobInventoryDetails.filter(jid => {
      return (
        jid.productionPhase === phase.productionPhase &&
        (jid.sortingCode === phase.sortingCode || (phase.sortingCode.length === 0 && jid.sortingCode === null))
      )
    })

    if (jids.length > 0) {
      const remainingQuantity = jids.map(jid => jid.remainingQuantity).reduce((x, y) => x + y, 0)

      const remQtyPerUnitOfMeasure = jids[0]?.remQtyPerUnitOfMeasure
      const unitOfMeasure = jids[0]?.unitOfMeasure

      return {
        ...phase,
        remainingQuantity,
        remQtyPerUnitOfMeasure,
        unitOfMeasure,
      }
    } else {
      return phase
    }
  })

  return (
    <div>
      {combinedPhases &&
        combinedPhases.length > 0 &&
        combinedPhases.map(phase => (
          <PhaseSectionCard key={phase.id} phase={phase} unfilteredPhases={unfilteredPhases} />
        ))}
    </div>
  )
}

const PhaseSectionCard = ({ phase, unfilteredPhases }: { phase: any; unfilteredPhases: JobProductionPhase[] }) => {
  const { t } = useTranslation()
  const [showEditDialog, setShowUpdateDialog] = useState<boolean>(false)

  const {
    productionPhase,
    sortingCode,
    startingDate,
    endDate,
    remainingQuantity,
    remQtyPerUnitOfMeasure,
    unitOfMeasure,
  } = phase

  const phaseText = productionPhase + (!sortingCode || sortingCode === '' ? '' : ` | ${sortingCode}`)
  const { numberFormat } = useAppSettingsContext()
  let quantityText = `${t('QUANTITY')}:\u00A0-`

  const unit = formatUnitOfMeasure(unitOfMeasure)
  const formatRemainingQuantity = formatQuantity(remainingQuantity, numberFormat)
  if (remainingQuantity) {
    quantityText = `${formatRemainingQuantity}\u00A0x\u00A0${remQtyPerUnitOfMeasure}`
  }

  const handleEdit = () => setShowUpdateDialog(true)
  const handleStopEdit = () => {
    setShowUpdateDialog(false)
  }

  return (
    <div key={phase.id} className='gh-phase-card'>
      <div className='gh-flex-column'>
        <div className='gh-phase-card-row' style={{ fontWeight: '400' }}>
          {phaseText}
        </div>
        <div className='gh-phase-card-left-row'>
          <div>{`${t(`START_DATE`)}: ${formatDateWithWeekNo(startingDate)}`}</div>

          <RoleVisible role='GREENHOUSE_CHANGE_PHASE_DATES'>
            <IconButton onClick={handleEdit} style={{ padding: '0 1rem' }}>
              <MaterialIcon value='edit' style={{ fontSize: `1rem` }} />
            </IconButton>
            {showEditDialog && (
              <EditPhaseStartDateDialog
                phaseText={phaseText}
                phase={phase}
                startingDate={phase.startingDate}
                unfilteredPhases={unfilteredPhases}
                open={showEditDialog}
                handleClose={handleStopEdit}
              />
            )}
          </RoleVisible>
        </div>
        <div className='gh-phase-card-left-row'>
          <div>{`${t('END')}: ${formatDateWithWeekNo(endDate)}`}</div>
        </div>
      </div>

      <div className='gh-phase-card-column-right'>
        <div> {quantityText}</div>
        {unit && <div>{`${unit}`}</div>}
      </div>
    </div>
  )
}
